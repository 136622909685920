//
//
//
//
//
//
//
//
//
//

const meta = {
  url: '/',
  title: 'The creative community for great feedback',
  description:
    'Upload your designs and get great feedback from other design professionals',
  img: 'https://justpx.com/hero.jpg',
  alt: 'Justpx web application first preview',
}

export default {
  head() {
    return {
      title: meta.title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: meta.description,
        },

        // Open Graph
        { hid: 'og:title', property: 'og:title', content: meta.title },
        {
          hid: 'og:description',
          property: 'og:description',
          content: meta.description,
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: meta.img,
        },
        {
          hid: 'og:image:alt',
          name: 'og:image:alt',
          content: meta.alt,
        },
        {
          hid: 'og:url',
          name: 'og:url',
          content: `https://justpx.com${meta.url}`,
        },
        {
          hid: 'og:type',
          name: 'og:type',
          content: 'website',
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          content: 'Justpx',
        },

        // Twitter Card
        {
          hid: 'twitter:title',
          name: 'twitter:title',
          content: meta.title,
        },
        {
          hid: 'twitter:description',
          name: 'twitter:description',
          content: meta.description,
        },
        {
          hid: 'twitter:image',
          name: 'twitter:image',
          content: meta.img,
        },
        {
          hid: 'twitter:image:alt',
          name: 'twitter:image:alt',
          content: meta.alt,
        },
      ],
    }
  },
}
