import { render, staticRenderFns } from "./index.vue?vue&type=template&id=1c7e9eb1&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c7e9eb1",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Hero: require('/opt/build/repo/components/Hero.vue').default,Statement: require('/opt/build/repo/components/Statement.vue').default,Features: require('/opt/build/repo/components/Features.vue').default,Connect: require('/opt/build/repo/components/Connect.vue').default,JoinUs: require('/opt/build/repo/components/JoinUs.vue').default,Layout: require('/opt/build/repo/components/Layout.vue').default})
