//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  methods: {
    sendEventRedirect($event, eventName) {
      window.sendEventRedirect($event, eventName)
    },
  },
}
