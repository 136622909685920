//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from 'axios'

export default {
  data() {
    return {
      email: '',
      isLoading: false,
    }
  },
  methods: {
    onSubmit($event) {
      this.isLoading = true

      let data = {
        email: this.email,
        source: window.location.href,
      }

      window.gtag('event', 'beta-signup')

      axios
        .post('https://api.justpx.com/open-api/beta/signup', data, {
          headers: {
            Accept: 'application/json',
          },
        })
        .then(
          (_) => {
            window.location.href = '/form-success'
          },
          (err) => {
            console.log('err: ', err)
            // Error
          }
        )
    },
  },
}
